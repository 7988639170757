import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { days } from "../../assets/svgIcons";
import AddLecture from "../../components/Lectures/add";
import DeleteLecture from "../../components/Lectures/delete";
import EditLecture from "../../components/Lectures/edit";
import ShowHideLectures from "../../components/Lectures/showHide";
import CustomTable from "../../components/table";
import { docUrl, secondUrl } from "../../utils/baseUrl";
import "./style.css";
import Modal from "../../components/modal";
import axios from "axios";
import Toast from "../../components/toast";
import Loader from "../../components/loader";
import { FaTrash } from "react-icons/fa";

function Lectures() {
  const [toast, setToast] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openShowHideModal, setOpenShowHideModal] = useState(false);
  const [initialLectureData, setInitialLectureData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [rowData, setRowData] = useState({});

  const columns = [
    {
      key: "name",
      title: "Lecture Name",
      dataIndex: "subject_name",
      search: true,
    },
    // {
    //   key: "lec_cover_link",
    //   title: "Image",
    //   dataIndex: "subject_image",
    //   render: (row) => {
    //     console.log(row);
    //     return (
    //       <img
    //         src={row}
    //         style={{ width: "100px" }}
    //         alt=""
    //         className=""
    //       />
    //     );
    //   },
    // },
    {
      key: "description",
      title: "Description",
      dataIndex: "subject_description",
      
    },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "actions",
      render: (text, row) => (
        <div className="actions-btns">
          {/* <div
            className="delete-btn c-pointer text-danger"
            onClick={() => setOpenDeleteModal(row)}
          >
            <div className="tooltip">Delete</div>
            {deleteIcon}
          </div>
          <div
            onClick={() => setOpenShowHideModal(row)}
            className={
              row?.hidden
                ? "showhide-btn c-pointer text-success"
                : "showhide-btn c-pointer text-danger"
            }
          >
            <div className="tooltip">{row?.hidden ? "Show" : "Hide"}</div>
            {row?.hidden ? closedEye : openedEye}
          </div>
          <div
            className="open-btn c-pointer text-primary"
            onClick={() => setOpenEditModal(row)}
          >
            <div className="tooltip">Edit</div>
            {editIcon}
          </div> */}
          {/* <div
            className="open-btn c-pointer text-primary"
            onClick={() => navigate(`${row?.subject_id}/days`)}
          >
            <button className="btn btn-success">Days</button>
          </div> */}

          <div
          // className="open-btn c-pointer btn btn-primary text-primary"
          // onClick={() => navigate(`${row?.subject_id}/days`)}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                console.log(row);
                setRowData(row);
                setOpenEditModal(true);
              }}
            >
              Edit
            </button>
            {/* <div className="tooltip">Edit</div> */}
          </div>

          <div
          // className="open-btn c-pointer btn btn-primary text-primary"
          // onClick={() => navigate(`${row?.subject_id}/days`)}
          >
            <button
              className="btn btn-danger"
              onClick={() => {
                console.log(row);
                setRowData(row);
                setOpenDeleteModal(true);
              }}
            >
              Delete
            </button>
            {/* <div className="tooltip">Edit</div> */}
          </div>
        </div>
      ),
    },
  ];
  const { pack, group } = useParams();
  const getAllMyVediosFor = async () => {
    setIsPageLoading(true);

    let data_to_send = {
      package_id: pack,
      group_id: group,
    };

    console.log(JSON.stringify(data_to_send));

    try {
      const response = await fetch(docUrl + "/home/select_subject.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data_to_send),
      });

      const resData = await response.json();
      console.log(resData);

      if (Array.isArray(resData.subjects) && resData.subjects.length !== 0) {
        const allData = resData.subjects.map((item) => ({
          ...item,
          deleteLoading: false,
          editLoading: false,
        }));

        setInitialLectureData(allData);
      } else {
        setInitialLectureData([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    getAllMyVediosFor();
  }, []);

  function handleDeleteLecture() {
    // console.log(rowData);
    const data_send = {
      subject_id: rowData?.subject_id,
    };
    console.log(data_send);
    axios
      .post(docUrl + "/home/delete_subject.php", data_send)
      .then((res) => {
        if (res?.data?.status == "success") {
          setToast({ type: "success", message: res?.data?.message });
          getAllMyVediosFor();
          setOpenDeleteModal(false);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => setOpenDeleteModal(false));
  }

  function handleEditLecture(e) {
    e.preventDefault();
    const data_send = {
      subject_name: rowData.subject_name,
      subject_description: rowData.subject_description,
      subject_id: rowData.subject_id,
      lec_sub_id:8,
    };

    console.log(data_send);
    

    axios
      .post(docUrl + "/home/update_subject.php", JSON.stringify(data_send))
      .then((res) => {
        if (res?.data?.status == "success") {
          setToast({ type: "success", message: res?.data?.message });
          
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setOpenEditModal(false)
        getAllMyVediosFor();
        setToast(false)
      });
  }

  const handleImageChange = (e) => {
    setRowData({
      ...rowData,
      subject_image: URL.createObjectURL(e.target.files[0]),
    });
  };

  const removeImage = () => {
    setRowData({ ...rowData, subject_image: null });
  };

  return (
    <div className="lectures">
      <div className="tablePageHeader">
        <h1 className="pageTitle">Lectures djh</h1>
        <button
          className="btn btn-success"
          onClick={() => {
            setOpenModal(true);
          }}
        >
          اضافه محاضره
        </button>
      </div>
      <Modal
        visible={openDeleteModal}
        close={setOpenDeleteModal}
        title="حذف المحاضره"
      >
        <div className="delete-warning">
          <h3>Are you sure you want to delete this lecture?</h3>
          <p className="warning-message">
            This action cannot be undone. Please confirm that you want to delete
            the following lecture:
          </p>
          <div className="lecture-details">
            <strong>Title:</strong> {rowData?.subject_name}
          </div>
          <div className="lecture-details">
            <strong>Description:</strong> {rowData?.subject_description}
          </div>
         
          <div className="rowEndDiv">
            <>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLecture}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </button>
            </>
            {/* {rowData?.deleteLoading ? (
            <Loader />
          ) : (
            <>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleDeleteLecture}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setOpenDeleteModal(false)}
              >
                Cancel
              </button>
            </>
          )} */}
          </div>
        </div>
      </Modal>

      <Modal
        visible={openEditModal}
        close={setOpenEditModal}
        title="تعديل المحاضره"
      >
        <form onSubmit={handleEditLecture} className="animated-form">
          <div className="form-group">
            <label htmlFor="lectureName" className="form-label">
              Lecture Name
            </label>
            <input
              type="text"
              value={rowData.subject_name}
              id="lectureName"
              placeholder="Enter Lecture Name"
              onChange={(e) =>
                setRowData({ ...rowData, subject_name: e.target.value })
              }
              className="form-input"
            />
          </div>
          {/* <div className="form-group">
          <label htmlFor="lecturenumber" className="form-label">
            Lecture Number
          </label>
          <input
            type="text"
            id="lecturenumber"
            value={rowData.lec_arrangement}
            placeholder="Enter Lecture number"
            onChange={(e) =>
              setRowData({
                ...rowData,
                lec_arrangement: e.target.value,
              })
            }
            className="form-input"
          />
        </div>{" "} */}
          {/* <div className="form-group">
          <label htmlFor="lectureImage" className="form-label">
            Lecture Image (Optional)
          </label>
          <input
            type="file"
            id="lectureImage"
            onChange={handleImageChange}
            className="form-input"
          />
          {rowData.subject_image && (
            <div className="image-preview">
              <img
                src={(rowData.subject_image)}
                alt="Lecture Preview"
              />
              <button
                type="button"
                onClick={removeImage}
                className="remove-image-btn"
              >
                <FaTrash />
              </button>
            </div>
          )}
        </div> */}

          <div className="form-group">
            <label htmlFor="lectureDescription" className="form-label">
              Description
            </label>
            <textarea
              value={rowData.subject_description}
              id="lectureDescription"
              placeholder="Enter Lecture Description"
              onChange={(e) =>
                setRowData({ ...rowData, subject_description: e.target.value })
              }
              className="form-input"
              rows="4"
            />
          </div>

          <div className="form-footer">
            {rowData?.editLoading ? (
              <Loader />
            ) : (
              <button type="submit" className="form-submit-btn">
                Save
              </button>
            )}
          </div>
        </form>
      </Modal>

      <CustomTable dataSource={initialLectureData} columns={columns} />
      <AddLecture
        toast={toast}
        setToast={setToast}
        getFunction={getAllMyVediosFor}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      {/* <EditLecture openModal={openEditModal} setOpenModal={setOpenEditModal} /> */}
      {/* <DeleteLecture
        openModal={openDeleteModal}
        setOpenModal={setOpenDeleteModal}
      /> */}
      <ShowHideLectures
        openModal={openShowHideModal}
        setOpenModal={setOpenShowHideModal}
      />
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(false)}
        />
      )}
    </div>
  );
}

export default Lectures;

import React, { useEffect, useState } from "react";
import Modal from "../../../modal";
import {
  addNew,
  deleteIcon,
  editIcon,
  pdfIcon,
} from "../../../../assets/svgIcons";
import "./style.css";
import DeletePdf from "./deletePDf";
import EditPdf from "./editVideo";

function PdfOpen({ openModal, setOpenModal, getFunction }) {
  const [openDelete, setOpenDelete] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  // Refresh the data when a PDF is added or deleted
  const refreshData = async () => {
    const updatedData = await getFunction(); // Fetch updated data
    setOpenModal(updatedData); // Ensure openModal is updated with new data
  };

  return (
    <>
      <Modal
        close={() => setOpenModal(false)}
        footer={false}
        title={"PDF"}
        visible={openModal}
      >
        {openModal?.attach_pdf && openModal?.attach_pdf?.length ? (
          <div className="pdfView">
            <span>{pdfIcon}</span>
            <a href={openModal?.attach_pdf} target="_blank" rel="noopener noreferrer">
              {openModal?.video_title}  pdf
            </a>

            <pre>
              <p onClick={() => setOpenDelete(openModal)}>{deleteIcon}</p>
            </pre>
          </div>
        ) : null}
        <p
          className="createnew"
          onClick={() => {
            setOpenEdit(openModal);
            setIsEdit(false);
          }}
        >
          <span>{addNew}</span> <span>Add / Edit PDF</span>
        </p>
      </Modal>
      <EditPdf
        getFunction={refreshData} // Refresh data after adding or editing
        openModal={openEdit}
        setOpenModal={setOpenEdit}
        edit={isEdit}
      />
      <DeletePdf
        getFunction={refreshData} // Refresh data after deletion
        openModal={openDelete}
        setOpenModal={setOpenDelete}
      />
    </>
  );
}

export default PdfOpen;
